import axios from 'axios'
// 导入进度条的库和样式文件
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export function request (config) {
  let requestUrl = window.g.SERVER_URL
  // eslint-disable-next-line no-prototype-builtins
  if (config.hasOwnProperty('baseUrl')) {
    requestUrl = config.baseUrl
  }
  const instance = axios.create({
    // baseURL: 'https://www.liulongbin.top:8888/api/private/v1',
    baseURL: requestUrl,
    timeout: 50000
  })
  // 拦截请求。请求在到达服务器之前会首先调用这个函数对请求做一些预处理
  // 在这里展示进度条
  instance.interceptors.request.use(config => {
    NProgress.start()
    // 为请求头对象，添加Token验证的Authorization字段
    config.headers.Authorization = sessionStorage.getItem('token')
    return config
  })

  // 拦截响应。在这里隐藏进度条
  instance.interceptors.response.use(response => {
    NProgress.done()
    return response
  }, error => {
    NProgress.done()
    return error // 返回接口返回的错误信息
  })

  return instance(config)
}
